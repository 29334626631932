import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHeader from '../components/reusable/PageHeader';
import Phones from '../components/reusable/Phones';


const ThankYouPage = () => {
 return (
  <Layout>
    <SEO title="Thank you" />
    <PageHeader title="Thank you" />
    <div className="container" style={{minHeight: '15rem'}}>
      Thank you for you message.
      <br />
      We will get in touch with you soon.
    </div>
    <Phones />
  </Layout>
  )
}

export default ThankYouPage;
